// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "_variables";

body {
  font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif;
  background-color: #eee;
}

h1, h2, h3, h4, h5, h6, .title {
  color: $main-color;
}

a {
  color: $main-color;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.cowpay-spinner {
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  margin: 20px 0;
  border: 0px solid #27ae60;
  border-radius: 50%;
  box-shadow: 0 -20px 0 24px #27ae60 inset;
  animation: cowpayspinnerrotate 1s infinite linear;
}

@keyframes cowpayspinnerrotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cowpay-spinner {
  margin: 10px auto;
  border: 0px solid #920713;
  box-shadow: 0 -20px 0 24px #920713 inset;
}

#order-wait {
  text-align: center;
}
.btn-pay-cancel {
  margin-right: 10px;
}

.navbar-default {
  background: none;
  border: none;
}

.navbar-header {
  text-align: center;
  float: none;
}

.logo {
  margin: 10px;
}

// Payment screen
.panel-default>.panel-heading {
  color: #FFF;
  font-weight: bold;
  background-color: $main-color;
  border-color: #ddd;
}

.btn-primary {
  color: #fff;
  background-color: $main-color;
  border-color: $main-color;
  transition: all 200ms linear 0ms;
  text-decoration: none;

  &:hover {
    background: #c9c8c6;
    color: #ffffff;
    text-decoration: none;
  }
}
